import recallServices from '../../../services/supplier/supplier-recall'
import Communicationdetails from '../../share/communication-view/supplier/communication-details'
import ChangeHistory from '../../share/communication-view/supplier/change-history'
import SupplierActions from '../../share/communication-view/supplier/supplier-actions'
import SupplierCommunications from '../../share/communication-view/supplier/supplier-communications'
import ReportDetails from '../../share/communication-view/supplier/report-details'
import router from '../../../router/paths'
import WorkflowActions from '../../share/WorkflowActions'
import PaperResponse from '../../share/communication-view/supplier/paper-response'
import AffectedLocations from '../../share/communication-view/supplier/affected-locations'
import moment from 'moment-timezone'
import { COMMUNICATION_TYPE, FILEPREVIEW, OUTPUT_DATE_FORMAT } from '../../../misc/commons'
export default {
    props: {
        id: {type: String, default: null},
        providerCommunicationId: {type: Number, default: null},
        communicationType: {type: String, default: null},
    },
    components: {
        Communicationdetails,
        ChangeHistory,
        SupplierActions,
        SupplierCommunications,
        WorkflowActions,
        ReportDetails,
        PaperResponse,
        AffectedLocations
    },
    data() {
        return {
            item: null,
            recallEditPathName: router.supp_Recall_EditItem.name,
            tabWorkflow: false,
            disabledDelegate: false,
            tabDetailsKey: 0,
            tabPaperResponseKey: 0,
            tabDetails: false,
            tabActions: false,
            tabCommunications: false,
            tabHistory: false,
            tabAffectedLocation: false,
            tabPaperResponse: false,
            selectedSendNotification: null,
            selectedNonCoveredSendNotification: null,
            showSendNoti: false,
            completedRecall: false,
            showEdit: false,
            showCloseOpen: false,
            showDelete: false,
            isProviderCreatedRecall: false,
            tabReports: false,
            unreadMessages: 0,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            FILEPREVIEW: FILEPREVIEW,
            admin: [],
            documents: [],
            userName: '',
            activeTab: 1,
            paperNotificationDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            disabledNotificationDate: false,
            disabledDates: {
                from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            },
        }
    },
    watch: {
        item(val) {
            if (val.status && val.status == 'OPEN') {
                this.showSendNoti = true
                this.showEdit = true
                this.showCloseOpen = true
                this.showDelete = false
            } else if (val.status && val.status == 'CLOSED') {
                this.showSendNoti = false
                this.showEdit = false
                this.showCloseOpen = true
                this.showDelete = false
            } else if (val.status && val.status == 'DRAFT') {
                this.showSendNoti = true
                this.showEdit = true
                this.showCloseOpen = true
                this.showDelete = true
            }
        },
        selectedSendNotification(newValue) {
            if (newValue) {
                this.selectedNonCoveredSendNotification = null
            }
        },
        selectedNonCoveredSendNotification(newValue) {
            if (newValue) {
                this.selectedSendNotification = null
            }
        }
    },
    computed: {
        sendOptions() {
            if (this.item.status == 'DRAFT' && this.item) {
                return [
                    { text: 'All providers (' + this.item.send_all + ')', value: 'SEND_ALL', disabled: !(this.item.send_all > 0)},
                    { text: 'New providers (' + this.item.send_new + ')', value: 'SEND_NEW' , disabled: !(this.item.send_new > 0)},
                ]

            }
            if (this.item.status != 'DRAFT'  && this.item) {
                return [
                    { text: 'All providers (' + this.item.send_all + ')', value: 'SEND_ALL', disabled: !(this.item.send_all > 0)},
                    { text: 'New providers (' + this.item.send_new + ')', value: 'SEND_NEW' , disabled: !(this.item.send_new > 0)},
                    { text: 'Non-acknowledged providers (' + this.item.acknowledge_providers + ')', value: 'NON_ACKNOWLEDGED_PROVIDERS' , disabled: !(this.item.acknowledge_providers > 0)}
                ]
            }
        },
        sendNonCoveredLocationOptions() {
            if (this.item.status == 'DRAFT' && this.item) {
                return [
                    { text: 'All Locations (' + this.item.noncovered_send_all + ')', value: 'SEND_ALL_NC', disabled: !(this.item.noncovered_send_all > 0)},
                    { text: 'New Locations (' + this.item.noncovered_send_new + ')', value: 'SEND_NEW_NC' , disabled: !(this.item.noncovered_send_new > 0)},
                ]

            }
            if (this.item.status != 'DRAFT'  && this.item) {
                return [
                    { text: 'All Locations (' + this.item.noncovered_send_all + ')', value: 'SEND_ALL_NC', disabled: !(this.item.noncovered_send_all > 0)},
                    { text: 'New Locations (' + this.item.noncovered_send_new + ')', value: 'SEND_NEW_NC' , disabled: !(this.item.noncovered_send_new > 0)},
                    { text: 'Non-responded Locations (' + this.item.noncovered_nonacknowledge + ')', value: 'NON_RESPONDED_NC' , disabled: !(this.item.noncovered_nonacknowledge > 0)}
                ]
            }
        },
    },
    created() {
        this.communicationType = COMMUNICATION_TYPE.RECALL
        if (this.$route.query.conId && this.$route.query.conId > 0) {
            this.tabCommunications = true
        } else {
            this.tabDetails = true
        }
        this.getCommunicationSummaryById()
    },
    methods: {
        handleSendNotificationChange(value) {
            if (value) {
                this.selectedNonCoveredSendNotification = null
            }
        },
        handleNonCoveredSendNotificationChange(value) {
            if (value) {
                this.selectedSendNotification = null
            }
        },
        getCommunicationSummaryById() {
            recallServices.getCommunicationSummaryById(this.id).then(resp => {
                if (resp.error){
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    var userData = this.$store.state.user
                    this.admin.username = userData.fname + ' ' + userData.lname
                    this.admin.title = userData.title
                    this.item = resp.data.d
                    this.unreadMessages = this.item.unreadMessageCount
                    if(this.item.status == 'CLOSED')
                    {
                        this.disabledDelegate=true
                        this.completedRecall=true
                    }
                    else
                    {
                        this.disabledDelegate=false
                    }
                }
            })
        },
        getCurrentDateInUTC() {
            const today = new Date()
            return today.toUTCString()
        },
        async handleDocumentsUpdated(documents) {
            const updatedDocuments = await Promise.all(documents.map(async (document) => {
                try {
                    const resp = await recallServices.getDocumentById(document.id)
                    if (!resp.error) {
                        const [typeMain, typeSub] = document.type.split('/')
                        let fileType = this.getFileType(typeMain, typeSub)
                        if (fileType === 'xlsx' || fileType === 'doc' || fileType === 'ppt') {
                            resp.data.d.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(resp.data.d.signed_url_review)
                        }
                        return {
                            ...document,
                            fileType,
                            signed_url: resp.data.d.signed_url,
                            signed_url_review: resp.data.d.signed_url_review,
                        }
                    } else {
                        throw new Error(`Error fetching document: ${document.id}`)
                    }
                } catch (error) {
                    console.error('Error fetching signed URL for document:', document.id, error)
                    return {
                        ...document,
                        signed_url: '',
                        error: true,
                    }
                }
            }))
            this.documents = updatedDocuments
        },
        getFileType(typeMain, typeSub) {
            if (typeMain === 'image') return 'image'
            if (typeMain === 'video') return 'video'
            if (typeMain === 'application' && (typeSub === 'msword' || typeSub === 'vnd.openxmlformats-officedocument.wordprocessingml.document')) return 'doc'
            if (typeMain === 'application' && (typeSub === 'vnd.ms-excel' || typeSub === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')) return 'xlsx'
            if (typeMain === 'application' && typeSub === 'pdf') return 'pdf'
            if (typeMain === 'application' && (typeSub === 'vnd.openxmlformats-officedocument.presentationml.presentation' || typeSub === 'vnd.ms-powerpoint')) return 'ppt'
            if (typeMain === 'text' && typeSub === 'csv') return 'csv'
            return 'unsupported'
        },
        downloadFDAReportFile()
        {
            recallServices.downloadFDAReportFile({
                communicationId: this.id,
                communicationUID: this.item.communicationUID
            }).then(resp => {
                if (!resp.error) {
                    window.open(resp.data.d, '_blank')
                }
            })
        },
        goToEditRecall() {
            this.$router.push({
                name: this.recallEditPathName,
                params: { id: this.id }
            })
        },
        closeSendNotification() {
            this.selectedSendNotification = null
            this.selectedNonCoveredSendNotification = null
            this.$refs.sendNotification.hide()
        },
        closePaperSendNotification() {
            this.selectedSendNotification = null
            this.selectedNonCoveredSendNotification = null
            this.$refs.paperNotification.hide()
        },
        approveRecall(userName) {
            recallServices.approveRecall(this.id, this.communicationType, userName).then(resp => {
                if (!resp.error) {
                    this.$refs.approval.hide()
                    this.getCommunicationSummaryById()
                    if (this.tabDetails == true) {
                        this.tabDetailsKey += 1
                    }
                    this._showToast('Recall Approved!')
                    this.selectedSendNotification = null
                    this.selectedNonCoveredSendNotification = null
                    this.$refs.sendNotification.show()
                }
            })
        },
        closeApproveRecallModal() {
            this.$refs.approval.hide()
        },
        openRecallModal() {
            this.selectedSendNotification = null
            this.selectedNonCoveredSendNotification = null
            this.$refs.sendNotification.show()
        },
        paperNotificationModal() {
            if(this.selectedNonCoveredSendNotification == 'SEND_ALL_NC' || this.selectedNonCoveredSendNotification == 'SEND_NEW_NC' || this.selectedNonCoveredSendNotification == 'NON_RESPONDED_NC')
            {
                recallServices.getRecallById(this.id).then(resp => {
                    if (!resp.error) {
                        if(new Date(new Date(resp.data.d.communicationDate.split(' ')[0]).getFullYear(), new Date(resp.data.d.communicationDate.split(' ')[0]).getMonth(), (new Date(resp.data.d.communicationDate.split(' ')[0]).getDate())).toISOString().split('T')[0] == new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0])
                        {
                            this.disabledNotificationDate = true
                            this.paperNotificationDate = null
                            const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                            const tomorrow = new Date(today)
                            tomorrow.setDate(today.getDate() - 1)
                            this.disabledDates.from = tomorrow
                            this.disabledDates.to = new Date(new Date(resp.data.d.communicationDate.split(' ')[0]).getFullYear(), new Date(resp.data.d.communicationDate.split(' ')[0]).getMonth(), (new Date(resp.data.d.communicationDate.split(' ')[0]).getDate()))
                        }
                        else
                        {
                            this.paperNotificationDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                            this.disabledDates.to = new Date(new Date(resp.data.d.communicationDate.split(' ')[0]).getFullYear(), new Date(resp.data.d.communicationDate.split(' ')[0]).getMonth(), (new Date(resp.data.d.communicationDate.split(' ')[0]).getDate()))
                        }
                        this.$refs.sendNotification.hide()
                        this.$refs.paperNotification.show()
                    }
                })
            }
            else
            {
                this.sendNotification()
            }
        },
        sendNotification() {
            if(this.item.allow_paperresponse && this.item.noncovered_send_all > 0 && this.paperNotificationDate == null)
            {
                this._showToast('Please select paper notification date', {variant: 'danger'})
            }
            else
            {
                if (['SEND_ALL', 'SEND_NEW', 'NON_ACKNOWLEDGED_PROVIDERS'].includes(this.selectedSendNotification) || ['SEND_ALL_NC', 'SEND_NEW_NC', 'NON_RESPONDED_NC'].includes(this.selectedNonCoveredSendNotification)) {
                    recallServices.sendNotification(this.id, this.selectedSendNotification, this.communicationType, this.paperNotificationDate != null ? moment(this.paperNotificationDate).format('YYYY/MM/DD') : this.paperNotificationDate,this.selectedNonCoveredSendNotification).then(resp => {
                        if (!resp.error) {
                            this.selectedSendNotification = null
                            this.selectedNonCoveredSendNotification = null
                            this.$refs.sendNotification.hide()
                            this.$refs.paperNotification.hide()
                            this.getCommunicationSummaryById()
                            if (this.tabDetails == true) {
                                this.tabDetailsKey += 1
                            }
                            this._showToast('Sent Successfully!')
                        }
                    })
                } else {
                    this._showToast('Please select an option', {variant: 'danger'})
                }
            }
        },
        onReLoadCommunicationData(){
            this.getCommunicationSummaryById()
        },
        closeOpenCommunication(){
            var isClose
            if (this.item.status == 'CLOSED'){
                isClose = false
            }
            if (['OPEN', 'DRAFT'].includes(this.item.status)){
                isClose = true
            }
            this._showConfirm(isClose == true ? 'Are you sure you want to close the recall?' : 'Are you sure you want to reopen the recall?', {title: isClose == true ? 'Close Recall' : 'Open Recall', variant: 'warning'}).then(confirm => {
                if (confirm) {
                    recallServices.closeOpenCommunication(this.id).then(resp => {
                        if (!resp.error) {
                            this.getCommunicationSummaryById()
                            isClose == true ? this._showToast('Recall Closed!') : this._showToast('Recall Opened!')
                            if (this.tabDetails == true) {
                                this.tabDetailsKey += 1
                            }
                            if (this.tabPaperResponse == true){
                                if (isClose)
                                    this.item.status = 'CLOSED'
                                this.tabPaperResponseKey += 1
                            }
                        }
                    })
                }
            })
        },
        exportproviderstausReport() {
            recallServices.getAuditReportById(this.item.id).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.exportAuditReport(this.item.id, resp.data.d.communicationUID)
                }
            })
        },
        downloadproviderstausReport() {
            recallServices.getAuditReportById(this.item.id).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'prov_Recall' })
                } else {
                    recallServices.downloadAuditReport(this.item.id, resp.data.d.communicationUID)
                }
            })
        },
        deleteCommunication(){
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', {title: 'Delete Recall', variant: 'danger'}).then(confirm => {
                if (confirm) {
                    recallServices.deleteCommunication(this.id,COMMUNICATION_TYPE.RECALL).then(resp => {
                        if (!resp.error) {
                            this.$router.push({ name: 'supp_Recall' })
                            this._showToast('Recall Deleted!')
                        }
                    })
                }
            })
        },

    }
}